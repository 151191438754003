<script>
	import { channel_selection, } from "@/api/recharge";
	export default {
		name:"MoneyTEMPLATE1",
		components: {
			'mt-tab-item': app.Mint.TabItem,
			'mt-button': app.Mint.Button,
			'mt-navbar': app.Mint.Navbar,
			'mt-tab-container': app.Mint.TabContainer,
			'mt-tab-container-item' : app.Mint.TabContainerItem,
		},
		props:[
			'onLine',
			'channelsData',
		],
		data() {
			return {				
                selected: '1',
			}
		},
		// inject:[
		// 	'channelsData'
		// ],
        methods:{                
            routerLink(it) {
                this.$router.push({ path: "/money/wechat_money/" + it.id + "/" + it.title + "/" + it.min + "/" + it.max + "/" + it.need_params });
            },
            hidden(url, type) { //1银联  2支付宝  3微信
                channel_selection(type).then(result => {
                    if (result.data.code == "SUCCESS") {
                        let payList = result.data.result;
                        if (payList.length == 0) {
                            app.Mint.Toast(getWord('coming_soon'));
                        } else {
                            this.$router.push({ path: url });
                        }
                    } else {
                        app.Mint.Toast(result.data.msg);
                    }
                })
            },
        },
	}
</script>
<template>
	<div>
        <mt-navbar v-model="selected">
            <mt-tab-item id="1" class="money_type"><span style="font-size: 0.3rem;border-top:0;">{{ getWord(['online2', 'payment']) }}</span></mt-tab-item>
            <mt-tab-item id="2" class="money_type"><span style="font-size: 0.3rem;border-top:0;">{{ getWord(['offline', 'payment']) }}</span></mt-tab-item>
        </mt-navbar>
        <mt-tab-container v-model="selected">
            <mt-tab-container-item id="1">
                 <a  v-for="(it,i) in onLine" :key="i" @click="routerLink(it);">
                        <mt-button size="large" style="margin-bottom:10px;">{{it.title}}</mt-button>
                    </a>
                <!-- <p class="moneyRouLink" v-for="(it,i) in onLine" :key="i" @click="routerLink(it);">{{it.title}}</p> -->
            </mt-tab-container-item>
            <mt-tab-container-item id="2">
                <div @click="hidden('/money/wechat_money_under','3')" v-if="channelsData.find(item=>item.id===3)">
                    <a>
                        <mt-button size="large">{{ channelsData.filter(item=>{return item.id===3})[0].name }}</mt-button>
                    </a>
                </div>
                <div style="margin-top:10px;" @click="hidden('/money/zfu_monye_under','2')" v-if="channelsData.find(item=>item.id===2)">
                    <a>
                        <mt-button size="large">{{ channelsData.filter(item=>{return item.id===2})[0].name }}</mt-button>
                    </a>
                </div>
                <div style="margin-top:10px;" @click="hidden('/money/yl_monye_under','1')" v-if="channelsData.find(item=>item.id===1)">
                    <a>
                        <mt-button size="large">{{ channelsData.filter(item=>{return item.id===1})[0].name }}</mt-button>
                    </a>
                </div>
            </mt-tab-container-item>
        </mt-tab-container>
    </div>
</template>
<style lang="scss" scoped type="text/css">
.moneyRouLink {
    background: rgb(247, 247, 247);
    width: 100%;
    height: 0.8rem;
    line-height: 0.8rem;
    text-align: center;
    font-size: 0.3rem;
    margin-bottom: 0.2rem;
    border-top: 1px solid rgb(241, 241, 241);
    border-bottom: 1px solid rgb(241, 241, 241);
    color: #666;
}
.mint-navbar .mint-tab-item.is-selected {
    margin-bottom: 0;
}
</style>